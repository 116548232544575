.dashboard__banner {
  padding: 29px 12px 19px 12px;
  background-color: rgba(0, 0, 0, 0.87);
  color: #fff;
  position: relative;
  padding-top: max(40px, calc(env(safe-area-inset-top) + 15px));
}

.notch_safe_padding_top {
  padding-top: max(40px, calc(env(safe-area-inset-top) + 10px));
}

.dashboard__black-strip {
  height: 60px;
  width: 100%;
background-color: rgba(0, 0, 0, 0.87);
  position: absolute;
  bottom: -60px;
  left: 0;
  z-index: -1;
}

.app_box_shadow {
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.11);
}
