@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
* {
  font-family: 'Inter', sans-serif;
}

/*Fix autofill color*/
input:-webkit-autofill {
  -webkit-text-fill-color: #000 !important;
}

body {
  color: #000;
}

:root {

  --ion-background-color: #fff;
  --ion-font-family: 'Inter', sans-serif;
  --ion-default-font: 'Inter', sans-serif;
  --ion-text-color: #000;
  --ion-text-color-rgb: rgb(0, 0, 0);
  --ion-highlight-color-focused: #000;
  --ion-tab-bar-color-selected: #000;
  --ion-tab-bar-color: rgba(0,0,0,0.57);
  --ion-color-primary: #1976d2;
  /* --ion-color-primary: #00ADEE;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;


  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;


  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;


  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;


  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;


  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;


  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;


  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;


  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-toolbar-background: #1f1f1f;
  --ion-tab-bar-background:#1f1f1f; */
}

.btn-pdt {
  font-size: 16px;
  text-transform: unset;
}

.btn-pdt--gray {
  font-size: 16px;
  color: #000;
  text-transform: unset;
  box-shadow:unset;
}

.component-box-shadow {
  box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.11);
}

.MuiInputBase-root-MuiFilledInput-root:after {
  border-bottom-color: '#1976d2';
}


/* .item-input.sc-ion-label-md-h, .item-input .sc-ion-label-md-h,
.item.sc-ion-label-md-h, .item .sc-ion-label-md-h{
  font-weight: 500;
} */
.dashboard__banner {
  padding: 29px 12px 19px 12px;
  background-color: rgba(0, 0, 0, 0.87);
  color: #fff;
  position: relative;
  padding-top: max(40px, calc(env(safe-area-inset-top) + 15px));
}

.notch_safe_padding_top {
  padding-top: max(40px, calc(env(safe-area-inset-top) + 10px));
}

.dashboard__black-strip {
  height: 60px;
  width: 100%;
background-color: rgba(0, 0, 0, 0.87);
  position: absolute;
  bottom: -60px;
  left: 0;
  z-index: -1;
}

.app_box_shadow {
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.11);
}

